import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import { Vendors } from '@codegen/cmsTypes';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { Carrier, LegFragment, RouteFragment } from '@codegen/gatewayUtils';
import { Language, VendorType } from '@shared/types/enums';
import Box from '@ui-v2/core/Box/Box';
import Icon from '@ui-v2/core/Icon/Icon';
import Text from '@ui-v2/core/Text/Text';
import {
  getTimeStringFromDayjs,
  getUTCDaysBetween,
  toUTCLocaleString,
  getDuration,
} from '@utils/dateUtils';
import {
  formatStationName,
  getPreviousLeg,
  getStopDuration,
} from '@utils/itineraryUtils';
import {
  centerHorizontally,
  centerVertically,
  createTypography,
} from '../../styles/base';
import { findVendorByIata, hasVendorType } from '../../utils/vendorUtils';
import CarrierLogo from '../CarrierLogos/CarrierLogo';
import Tooltip from '../Tooltip/Tooltip';

export interface Props {
  directionId: 'outbound' | 'homebound';
  iLeg: number;
  iRoute: number;
  isMobile?: boolean;
  itineraryPlaneIcon: ImageWithConfigFragment | null;
  language: Language;
  leg: LegFragment;
  routes: RouteFragment[];
  showItineraryVendorLogos: boolean;
  trainIcon: ImageWithConfigFragment | null;
  vendors: Vendors;
}

const StopRow = styled.div(({ theme: { spacings } }) => [
  centerVertically,
  css`
    position: relative;
    min-width: 200px;
    gap: ${spacings['16']}px;
  `,
]);

const TransportIconContainer = styled.div(
  ({ theme: { colours } }) => css`
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${colours.icons.default};
  `,
);

const StopText = styled.div(() => [
  centerHorizontally,
  css`
    flex: 1 0 0;
    flex-direction: column;
  `,
]);

const Airport = styled.span(({ theme: { typography } }) => [
  createTypography(typography.heading05),
]);

const DateTime = styled.div(({ theme: { colours, spacings, typography } }) => [
  createTypography(typography.body01),
  css`
    color: ${colours.text.subdued};
    gap: ${spacings['16']}px;
  `,
]);

export const LegRow = styled(StopRow)(() => [
  centerVertically,
  css`
    min-height: 80px;
    max-height: 80px;
  `,
]);

export const LineContainer = styled.div(() => [
  centerHorizontally,
  css`
    min-width: 20px;
    align-self: stretch;
  `,
]);

const Line = styled.div(
  ({ theme: { colours } }) => css`
    border: 1px solid ${colours.border.subdued};
  `,
);

export const DottedLine = styled(Line)(css`
  border-style: dashed;
`);

export const LegText = styled.div(
  ({ theme: { colours, spacings, typography } }) => [
    centerVertically,
    createTypography(typography.body02),
    css`
      flex-wrap: wrap;
      align-content: center;
      padding: ${spacings['16']}px 0;
      color: ${colours.text.subdued};
      gap: ${spacings['8']}px;
    `,
  ],
);

const Flight = styled.span(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-shrink: 0;
    gap: ${spacings['4']}px;
  `,
);

const StyledCarrierLogo = styled(CarrierLogo)(
  ({ theme: { spacings } }) => css`
    margin-right: ${spacings['8']}px;
  `,
);

const ItineraryLeg = ({
  directionId,
  iLeg,
  iRoute,
  isMobile,
  itineraryPlaneIcon,
  language,
  leg,
  routes,
  showItineraryVendorLogos,
  trainIcon,
  vendors,
}: Props) => {
  const { t } = useTranslation();
  const route = routes[iRoute];

  if (!route) {
    return null;
  }

  const isLastLeg = iLeg === route.legs.length - 1;
  const { marketingCarrier, operatingCarrier } = leg;
  const marketingCarrierCode = (marketingCarrier as Carrier).code;
  const operatingCarrierCode = (operatingCarrier as Carrier).code;

  const marketingVendor = findVendorByIata(vendors, marketingCarrierCode);
  const operatingVendor = findVendorByIata(vendors, operatingCarrierCode);

  const lastLegArrival = getPreviousLeg(routes, iRoute, iLeg)?.arrival ?? null;
  const arrivalDateDifference = getUTCDaysBetween(leg.departure, leg.arrival);
  const departureDateDifference = lastLegArrival
    ? getUTCDaysBetween(lastLegArrival, leg.departure)
    : 0;

  const [days, hours, minutes] = getDuration(leg.duration);
  const [stopDays, stopHours, stopMinutes] = getDuration(
    getStopDuration(routes, iRoute, iLeg) ?? 0,
  );

  const isRail = operatingVendor
    ? hasVendorType([operatingVendor], VendorType.RAIL)
    : undefined;

  const transportIcon = isRail ? trainIcon : itineraryPlaneIcon;

  const departureDateTimeId = `${leg.departure
    .toString()
    .replace(/\s+/g, '')
    .replaceAll(',', '_')}_${leg.id}_${isMobile ? 'mobile' : 'desktop'}`;

  const arrivalDateTimeId = `${leg.arrival
    .toString()
    .replace(/\s+/g, '')
    .replaceAll(',', '_')}_${leg.id}_${isMobile ? 'mobile' : 'desktop'}`;

  return (
    <>
      <StopRow>
        <TransportIconContainer>
          {transportIcon && (
            <Icon colour="icons.inverse" icon={transportIcon} size={12} />
          )}
        </TransportIconContainer>

        <StopText>
          <Airport>{formatStationName(leg.origin, operatingVendor)}</Airport>

          <DateTime id={departureDateTimeId}>
            <Tooltip
              id={departureDateTimeId}
              text={toUTCLocaleString({
                date: leg.departure.toDate(),
                locale: language,
                options: {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  weekday: 'long',
                  hour: '2-digit',
                  minute: '2-digit',

                  hourCycle: 'h23',
                },
              })}
            >
              <Box alignItems="center" display="inline-flex" gap={16}>
                {`${toUTCLocaleString({
                  date: leg.departure.toDate(),
                  locale: language,
                  options: {
                    day: '2-digit',
                    month: 'short',
                  },
                })}, ${getTimeStringFromDayjs(leg.departure, language)}`}
                {departureDateDifference > 0 && (
                  <Text
                    as="span"
                    colour="brand.primary"
                    variant="heading-7"
                  >{`+${departureDateDifference} ${
                    departureDateDifference === 1 ? t('day') : t('days')
                  }`}</Text>
                )}
              </Box>
            </Tooltip>
          </DateTime>
        </StopText>
      </StopRow>
      <LegRow>
        <LineContainer>
          <Line />
        </LineContainer>

        <LegText>
          <span>
            {`${
              days
                ? t(
                    '{{days}}d {{hours}}h {{minutes}}m',
                    { days, hours, minutes },
                    { default: `${days}d ${hours}h ${minutes}m` },
                  )
                : t(
                    '{{hours}}h {{minutes}}m',
                    { hours, minutes },
                    { default: `${hours}h ${minutes}m` },
                  )
            }`}
          </span>

          <Flight>
            <span>{marketingVendor?.name ?? ''}</span>

            {/* This class name is for google flights crawler */}
            <span className={`${directionId} flightNumber`}>
              {leg.marketingCarrier?.flightNumber}
            </span>
          </Flight>

          {marketingVendor && showItineraryVendorLogos && (
            <StyledCarrierLogo
              iconHeight={24}
              logo={marketingVendor.logo}
              vendorName={marketingVendor.name}
            />
          )}
        </LegText>
      </LegRow>
      <StopRow>
        <TransportIconContainer>
          {transportIcon && (
            <Icon colour="icons.inverse" icon={transportIcon} size={12} />
          )}
        </TransportIconContainer>

        <StopText>
          <Airport>
            {formatStationName(leg.destination, operatingVendor)}
          </Airport>

          <DateTime id={arrivalDateTimeId}>
            <Tooltip
              id={arrivalDateTimeId}
              text={toUTCLocaleString({
                date: leg.arrival.toDate(),
                locale: language,
                options: {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  weekday: 'long',
                  hour: '2-digit',
                  minute: '2-digit',
                  hourCycle: 'h23',
                },
              })}
            >
              <Box alignItems="center" display="inline-flex" gap={16}>
                {`${toUTCLocaleString({
                  date: leg.arrival.toDate(),
                  locale: language,
                  options: {
                    day: '2-digit',
                    month: 'short',
                  },
                })}, ${getTimeStringFromDayjs(leg.arrival, language)}`}
                {arrivalDateDifference > 0 && (
                  <Text
                    as="span"
                    colour="brand.primary"
                    variant="heading-7"
                  >{`+${arrivalDateDifference} ${
                    arrivalDateDifference === 1 ? t('day') : t('days')
                  }`}</Text>
                )}
              </Box>
            </Tooltip>
          </DateTime>
        </StopText>
      </StopRow>
      {!isLastLeg && (
        <LegRow>
          <LineContainer>
            <DottedLine />
          </LineContainer>

          <LegText>
            <span>
              {`${
                days
                  ? t(
                      '{{days}}d {{hours}}h {{minutes}}m',
                      {
                        days: stopDays,
                        hours: stopHours,
                        minutes: stopMinutes,
                      },
                      { default: `${stopDays}d ${stopHours}h ${stopMinutes}m` },
                    )
                  : t(
                      '{{hours}}h {{minutes}}m',
                      { hours: stopHours, minutes: stopMinutes },
                      { default: `${stopHours}h ${stopMinutes}m` },
                    )
              }`}
            </span>

            <span>{t('connection')}</span>
          </LegText>
        </LegRow>
      )}
    </>
  );
};

export default ItineraryLeg;
