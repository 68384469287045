import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import { Vendors } from '@codegen/cmsTypes';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { RouteFragment, Route } from '@codegen/gatewayUtils';
import { DisplayType, Language } from '@shared/types/enums';
import Text from '@ui-v2/core/Text/Text';
import { getDuration } from '@utils/dateUtils';
import { getStopDuration, getIsTrain } from '@utils/itineraryUtils';
import { boundHasOvernightStay, getTotalDuration } from '@utils/routeUtils';
import { GridColumn } from '../Grid/Grid';
import ItineraryConnectionInformation from './ItineraryConnectionInformation';
import ItineraryLeg from './ItineraryLeg';

export interface Props {
  directionId: 'outbound' | 'homebound';
  dohopConnectName: string;
  hasNoCheckedBagsConnection?: boolean;
  informationIcon: ImageWithConfigFragment | null;
  isMobile?: boolean;
  itineraryPlaneIcon: ImageWithConfigFragment | null;
  language: Language;
  residency: string;
  routes: RouteFragment[];
  showItineraryVendorLogos: boolean;
  showSelfConnectList?: boolean;
  showSelfTransfer?: boolean;
  title: string;
  toggleInformationModal?: () => void;
  trainIcon: ImageWithConfigFragment | null;
  vendors: Vendors;
  warningIcon: ImageWithConfigFragment | null;
}

const Container = styled(GridColumn)(
  ({ theme: { colours, spacings } }) => css`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    padding: 0;
    color: ${colours.text.default};
    gap: ${spacings['24']}px;
  `,
);

const TitleContainer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacings['4']}px;
  `,
);

const ItineraryDirection = ({
  directionId,
  dohopConnectName,
  hasNoCheckedBagsConnection,
  informationIcon,
  isMobile,
  itineraryPlaneIcon,
  language,
  residency,
  routes,
  showItineraryVendorLogos,
  showSelfConnectList,
  showSelfTransfer,
  title,
  toggleInformationModal,
  trainIcon,
  vendors,
  warningIcon,
}: Props) => {
  const { t } = useTranslation();

  const [days, hours, minutes] = getDuration(getTotalDuration(routes));

  return (
    <Container px={DisplayType.Small}>
      <TitleContainer>
        <Text as="span" variant="heading-4">
          {title}
        </Text>
        <Text as="span" colour="text.subdued">
          {`${t('journey_duration')}: `}
          {days
            ? t(
                '{{days}}d {{hours}}h {{minutes}}m',
                { days, hours, minutes },
                { default: `${days}d ${hours}h ${minutes}m` },
              )
            : t(
                '{{hours}}h {{minutes}}m',
                { hours, minutes },
                { default: `${hours}h ${minutes}m` },
              )}
        </Text>
      </TitleContainer>

      <div>
        {routes.map((route, iRoute) => {
          const stopDuration = getStopDuration(
            routes,
            iRoute,
            route.legs.length - 1,
          );

          const stopOrigin = route.destination.name;
          const stopDestination = routes[iRoute + 1]?.origin.name;
          const hasOvernightStay =
            routes[iRoute + 1] &&
            boundHasOvernightStay([route, routes[iRoute + 1] as Route]);

          const nextLegIsTrain = routes[iRoute + 1]?.legs.some((leg) =>
            getIsTrain(leg.carrierType),
          );

          return (
            <React.Fragment key={route.id}>
              {route.legs.map((leg, iLeg) => (
                <ItineraryLeg
                  directionId={directionId}
                  iLeg={iLeg}
                  iRoute={iRoute}
                  isMobile={isMobile}
                  itineraryPlaneIcon={itineraryPlaneIcon}
                  key={leg.id}
                  language={language}
                  leg={leg}
                  routes={routes}
                  showItineraryVendorLogos={showItineraryVendorLogos}
                  trainIcon={trainIcon}
                  vendors={vendors}
                />
              ))}
              {stopDuration && (
                <ItineraryConnectionInformation
                  dohopConnectName={dohopConnectName}
                  hasNoCheckedBagsConnection={hasNoCheckedBagsConnection}
                  hasOvernightStay={hasOvernightStay}
                  informationIcon={informationIcon}
                  nextLegIsTrain={nextLegIsTrain}
                  residency={residency}
                  showSelfConnectList={Boolean(
                    routes.length > 1 && showSelfConnectList,
                  )}
                  showSelfTransfer={showSelfTransfer}
                  stopDestination={stopDestination}
                  stopDuration={stopDuration}
                  stopOrigin={stopOrigin}
                  toggleInformationModal={toggleInformationModal}
                  warningIcon={warningIcon}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Container>
  );
};

export default ItineraryDirection;
